:root {
  --yellow: #020100;
  --pink: #ff19e9;
  --green: #00cc00;
  --white: #ffffff;
  --light-grey: #b1b1b1;
  --dark-grey: #2b2b2b;
  --black: #000000;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background:rgb(179, 128, 53);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.NFTlink {
  text-decoration: none;
  color:'#FFCC80';
  font-family: "Roboto";
}
::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #393812;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}
.logo {
  width: 600px;
}
.titleMinted {
  font-size: 35px;
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 350px;
  }
  .titleMinted {
    font-size: 24px;
  }
  .mintCost {
    font-size: 16px;
  }
  .nftimage {
    width: 200px;
  }
}

.mainBox {
  background-color:#529788;
  padding: 24px;
  border-radius: 24px;
  border: 3px dashed #ffffff;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 11px 2px;
}